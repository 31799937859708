<template>
  <div class="banner d-flex align-items-center">
      <div class="container">
          <div class="col-12">
              <div class="d-flex align-items-center" v-if="playerForecast!==null">
                <span class="player-team">
                  <img :src="`https://cdn.xmlteam.com/gamesv5/logos/l.nhl.com/official/${playerForecast.events[0][getPlayerTeam(playerForecast.events[0])]['team-key']}.png`" alt="">
                </span>
                <h2 class="player-name text-white">{{playerForecast.events[0].player.name}}</h2>
                <div class="ms-auto">
                  <!-- JSON download button -->
                  <a
                    class="btn btn-light"
                    role="button"
                    target="_blank"
                    :href="`https://formula.forecaster.ca/api/player-schedules/${$route.params.player}?season-id=2021`"
                    ><img :src="require('../assets/json-file.svg')" width="25" />
                  </a>
                </div>
              </div>
              <!-- <button> -->
              <!-- </button> -->
          </div>
      </div>
  </div>
  <div class="container">
      <div class="row">
          <div class="col-12">
              <!-- <pre>{{playerForecast}}</pre> -->
              <div class="table-responsive">
                  <table class="table my-4" id="playerForecast">
                      <thead>
                          <tr>
                              <th scope="col">Date</th>
                              <th scope="col">Time</th>
                              <th scope="col">Opp</th>
                              <th scope="col">
                                  Forecasted Goals
                                  <i v-if="currentSortDir == 'asc'" class="bi bi-caret-down-fill bi-color"></i>
                              </th>
                              <th scope="col">
                                  Forecasted Assists
                                  <i v-if="currentSortDir == 'asc'" class="bi bi-caret-down-fill bi-color"></i>
                              </th>
                              <th scope="col">
                                  Forecasted Points
                                  <i v-if="currentSortDir == 'asc'" class="bi bi-caret-down-fill bi-color"></i>
                              </th>
                              <th scope="col">Boost</th>
                              <th scope="col">Forecasted Game Score</th>
                          </tr>
                      </thead>
                      <tbody v-if="playerForecast!==null">
                          <tr v-for="match in futureGames" :key="match['id']">
                              <td align="left">{{match['event-date']}}</td>
                              <td>{{match['event-time']}}</td>
                              <td v-html="getOppValue(match)"></td>
                              <td>{{Number(match['forecast']['guessing-score']['score']).toFixed(2)}}</td>
                              <td>{{getForecastedAssistValue(match).toFixed(2)}}</td>
                              <td>{{(Number(match['forecast']['guessing-score']['score']) + getForecastedAssistValue(match)).toFixed(2)}}</td>
                              <!-- <td>-</td> -->
                              <td>
                                  <a
                                  class="link-primary text-decoration-none"
                                  role="button"
                                  >
                                    <span> {{ match['forecast']['guessing-score']['boost']>0 ? '+'+match['forecast']['guessing-score']['boost'] : match['forecast']['guessing-score']['boost']}}% </span>
                                  </a>
                              </td>
                              <td><router-link class="text-decoration-none" v-html="getForecastedGameScoreValue(match)" :to="`/${match['event-key']}/details`"></router-link></td>
                          </tr>
                      </tbody>
                        <tfoot>
                          <tr class="text-center">
                            <td colspan="3">Forecast for remaining {{futureGames.length}} games</td>
                            <td>{{ getTotalForecastedGoals().toFixed(2)}}</td>
                            <td>{{ getTotalForecastedAssist().toFixed(2)}}</td>
                            <td>{{ getTotalForecastedPoints().toFixed(2)}}</td>
                            <td>-</td>
                            <td>({{ (getTotalForecastedGoals() / futureGames.length).toFixed(2) }} G/GP)</td>
                          </tr>
                          <tr class="text-center">
                            <td colspan="3">Stats for prior {{priorGames.length}} games</td>
                            <td>{{ futureGames[0]['forecast']['guessings'][0]['intermediate-values']['goals-scored'] }}</td>
                            <td>{{ futureGames[0]['forecast']['guessings'][0]['intermediate-values']['actual-assists'] }}</td>
                            <td>
                              {{
                                futureGames[0]['forecast']['guessings'][0]['intermediate-values']['goals-scored']
                                + futureGames[0]['forecast']['guessings'][0]['intermediate-values']['actual-assists']
                              }}
                            </td>
                            <td>-</td>
                            <td>({{ (futureGames[0]['forecast']['guessings'][0]['intermediate-values']['goals-scored'] / priorGames.length).toFixed(2) }} G/GP)</td>
                          </tr>
                          <tr class="text-center">
                            <td colspan="3">Forecast for entire season</td>
                            <td>{{ (futureGames[0]['forecast']['guessings'][0]['intermediate-values']['goals-scored'] + getTotalForecastedGoals()).toFixed(2) }}</td>
                            <td>{{ (futureGames[0]['forecast']['guessings'][0]['intermediate-values']['actual-assists']+getTotalForecastedAssist()).toFixed(2)}}</td>
                            <td>
                              {{
                                ((Number(futureGames[0]['forecast']['guessings'][0]['intermediate-values']['goals-scored'] + getTotalForecastedGoals())) + (futureGames[0]['forecast']['guessings'][0]['intermediate-values']['actual-assists']+getTotalForecastedAssist())).toFixed(2)
                              }}
                            </td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        </tfoot>
                  </table>
              </div>
          </div>
      </div>
  </div>
  <!-- Modals -->
  <PlayerModal
    v-if="isModalVisible"
    :player="player"
    :awayTeam="awayTeam"
    :homeTeam="homeTeam"
    @close="closeModal()"
  ></PlayerModal>
</template>
<script>
import axios from "axios";
// import _ from "lodash";
import { mapGetters } from "vuex";
import PlayerModal from "../components/PlayerModal.vue"

export default {
  name: "GameForecast",
  title: "Game Forecast Prototype",
  components: {
    PlayerModal
  },

  data() {
    return {
      isLoading: false,
      // timezone: "ET",
      isModalVisible: false,
      playerForecast: null,
      awayTeam: null,
      homeTeam: null,
      player: null
    };
  },

  computed: {
    ...mapGetters(["startDate", "endDate"]),
    futureGames(){
      return this.playerForecast.events.filter(el => Date.parse(`${el['event-date']}T${el['event-time']}`) > Date.parse(new Date().toString()))
    },
    priorGames(){
      return this.playerForecast.events.filter(el => Date.parse(`${el['event-date']}T${el['event-time']}`) < Date.parse(new Date().toString()))
    }
  },

  methods: {
    getPlayerForecast() {
        axios.get(
            // `https://staging.formula.forecaster.ca/api/schedule/${this.$route.params.key}`
            `https://formula.forecaster.ca/api/player-schedules/${this.$route.params.player}?season-id=2021`
            )
            .then((r) => {
                this.playerForecast = r.data
            }).finally(() => {
                this.isLoading = false;
            });
    },

    getPlayerTeam(match){
      const teamId = match.player['team-id']
      if(match['away-team'].id === teamId) return 'away-team'
      if(match['home-team'].id === teamId) return 'home-team'
    },

    getOppValue(match){
      const teamId = match.player['team-id']
      if(match['away-team'].id === teamId) return `@ <img width="50px" src="https://cdn.xmlteam.com/gamesv5/logos/l.nhl.com/official/${match['home-team']['team-key']}.png"></img>${match['home-team'].abbreviation}`
      if(match['home-team'].id === teamId)
      return `vs <img width="50px" src="https://cdn.xmlteam.com/gamesv5/logos/l.nhl.com/official/${match['away-team']['team-key']}.png"></img>${match['away-team'].abbreviation}`
    },

    getForecastedAssistValue(match){
      const predicted_assist_season_percent = match['forecast']['guessings'][0]['intermediate-values']['predicted-assists-season-percent'] * 1
      const teamTotal = match[this.getPlayerTeam(match)]["guessing-score"]
      return Number((predicted_assist_season_percent/100) * teamTotal * 1.7)
    },

    getForecastedGameScoreValue(match){
      const homeTeamScore = Number(match['home-team']['guessing-score'])
      const homeTeamAbb = match['home-team']['abbreviation']
      const awayTeamScore = Number(match['away-team']['guessing-score'])
      const awayTeamAbb = match['away-team']['abbreviation']
      // return match
      const winLabel = `<span class='text-success'>W</span>`
      const loseLabel = `<span class='text-danger'>L</span>`
      if(this.getPlayerTeam(match) === 'home-team')
      return `${homeTeamAbb} ${homeTeamScore.toFixed(2)} at ${awayTeamAbb} ${awayTeamScore.toFixed(2)} - ${homeTeamScore < awayTeamScore ? loseLabel : winLabel}`
      if(this.getPlayerTeam(match) === 'away-team')
      return `${homeTeamAbb} ${homeTeamScore.toFixed(2)} at ${awayTeamAbb} ${awayTeamScore.toFixed(2)} - ${homeTeamScore < awayTeamScore ? winLabel : loseLabel}`
    },

    getTotalForecastedGoals(events = this.futureGames){
      let total = 0
      events.forEach(element => {
        total = total + Number(element['forecast']['guessing-score']['score'])
      });
      return total
    },

    getTotalForecastedAssist(events = this.futureGames){
      let total = 0
      events.forEach(element => {
        total += this.getForecastedAssistValue(element)
      });
      return total
    },

    getTotalForecastedPoints(events = this.futureGames){
      let total = 0
      events.forEach(element => {
        total += (Number(element['forecast']['guessing-score']['score']) + this.getForecastedAssistValue(element))
      });
      return total
    },

    showModal(match){
      this.player = match.player
      this.awayTeam = match['away-team']
      this.homeTeam = match['home-team']
      this.isModalVisible = true
    }
  },

  created() {
    document.title = this.$options.title;
  },

  beforeMount(){
      this.getPlayerForecast()
  },
};
</script>
<style scoped>
    .banner{
        height: 150px;
        background-image: url("https://sportsforecaster.com/images/player-bg.jpg");
    }
    table th,
    table tbody td{
      text-align: center;
    }
</style>
